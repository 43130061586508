import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestMethod, Response} from "@angular/http";
import {RequestConfig} from "../../../../shared/rest/rest.request";
import {CustomHttp} from "../../../../common/http/custom.http";
import {PageImpl} from "../../../../common/http/PageImpl";
import {TicketTypeRequestView, TipoTicket} from "../../config.classes";

@Injectable()
export class TipoTicketService {
    private CREATE_CODE = new RequestConfig("TPT", "application/json", true, RequestMethod.Post, "/types/tickets");
    private EDIT_CODE = new RequestConfig("TPT", "application/json", true, RequestMethod.Put, "/types/tickets/{typeId}");
    private DELETE_CODE = new RequestConfig("TPT", "application/json", true, RequestMethod.Delete, "/types/tickets/{typeId}");
    private GET_CODE = new RequestConfig("TPT", "application/json", true, RequestMethod.Get, "/types/tickets/{typeId}");
    private GET_PAGE = new RequestConfig("TPT", "application/json", true, RequestMethod.Get, "/types/tickets");
    private GET_ALL = new RequestConfig("TPT", "application/json", true, RequestMethod.Get, "/types/tickets/allTypesTicket");
    private GET_INFO_TYPES_TICKET = new RequestConfig("TPT", "application/json", true, RequestMethod.Post, "/types/tickets/find");

    constructor(private http: CustomHttp) {
    }

    /**
     * Envia una petición a WS para guardar tipos de ticket
     */
    saveTiposTicket(tipoTicket: TipoTicket): Observable<Response> {
        return this.http.sendRequest(this.CREATE_CODE, tipoTicket)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de tipo de ticket
     */
    getTipoTicket(idTicketType: number): Observable<TipoTicket> {
        return this.http.sendRequest(this.GET_CODE, {}, idTicketType)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de tipo de ticket
     */
    updateTipoTicket(tipoTicket: TipoTicket): Observable<Response> {
        return this.http.sendRequest(this.EDIT_CODE, tipoTicket, tipoTicket.idTicketType)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar datos de tipo de ticket
     */
    deleteTipoTicket(idTicketType: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, idTicketType)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Obtener todos los tipos de Ticket
     */
    findAll(): Observable<Array<TipoTicket>> {
        return this.http.sendRequest(this.GET_ALL)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    getPage(options: any): Observable<PageImpl<TipoTicket>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia una petición a WS para obtener informacion completa de x tipos de tickets
     */
    getInfoTiposTicket(tipoTicketView: TicketTypeRequestView): Observable<Array<TipoTicket>> {
        return this.http.sendRequest(this.GET_INFO_TYPES_TICKET, tipoTicketView).map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    findInfoTicketTypes(tipoTicketView: TicketTypeRequestView): Observable<Array<TipoTicket>> {
        return this.http.sendRequest(this.GET_INFO_TYPES_TICKET, tipoTicketView)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }
}
