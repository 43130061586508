import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DrawTreeComponent} from './draw-tree.component';


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [DrawTreeComponent],
    exports: [DrawTreeComponent]
})
export class DrawTreeModule {
}
