import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestMethod, Response} from "@angular/http";
import {RequestConfig, RestResponse} from "../../../../shared/rest/rest.request";
import {CustomHttp} from "../../../../common/http/custom.http";
import {PageImpl} from "../../../../common/http/PageImpl";
import {Categoria, TicketTypeRequestView} from "../../config.classes";

@Injectable()
export class CategoryService {
    private CREATE_CODE = new RequestConfig("TCA", "application/json", true, RequestMethod.Post, "/tickets/categories");
    private EDIT_CODE = new RequestConfig("TCA", "application/json", true, RequestMethod.Put, "/tickets/categories/{categoryId}");
    private DELETE_CODE = new RequestConfig("TCA", "application/json", true, RequestMethod.Delete, "/tickets/categories/{categoryId}");
    private GET_CODE = new RequestConfig("TCA", "application/json", true, RequestMethod.Get, "/tickets/categories/{categoryId}");
    private GET_PAGE = new RequestConfig("TCA", "application/json", true, RequestMethod.Get, "/tickets/categories");
    private GET_BY_TICKETTYPE = new RequestConfig("TCA", "application/json", true, RequestMethod.Get, "/tickets/categories/type/{idTypeTicket}");
    private GET_PARENTS = new RequestConfig("TCA", "application/json", true, RequestMethod.Get, "/tickets/categories/parents");
    private GET_CATEGORIES = new RequestConfig("TCA", "application/json", true, RequestMethod.Post, "/tickets/categories/find");

    constructor(private http: CustomHttp) {
    }

    /**
     * Envia una petición a WS para guardar autorizacion
     */
    saveCategory(categoria: Categoria): Observable<Response> {
        return this.http.sendRequest(this.CREATE_CODE, categoria)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de un autorizacion
     */
    getCategory(ticketCategoryId: number): Observable<Categoria> {
        return this.http.sendRequest(this.GET_CODE, {}, ticketCategoryId)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de autorizacion
     */
    updateCategory(categoria: Categoria): Observable<Response> {
        return this.http.sendRequest(this.EDIT_CODE, categoria, categoria.ticketCategoryId)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar datos de autorizacion
     */
    deleteCategory(ticketCategoryId: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, ticketCategoryId)
            .catch(error => this.http.handleError(error));
    }

    getAll(active?: boolean): Observable<Array<Categoria>> {
        return this.http.sendRequest(this.GET_PARENTS)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    searchCategories(request?: any): Observable<Array<Categoria>> {
        return this.http.sendRequest(this.GET_PAGE, request)
            .map((res: Response) => res.json().content)
            .catch(error => this.http.handleError(error));
    }

    getPage(options: any): Observable<PageImpl<Categoria>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getByTicketType(idTicketType: number): Observable<Array<Categoria>> {
        return this.http.sendRequest(this.GET_BY_TICKETTYPE, {}, idTicketType)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }

    getCategories(request: TicketTypeRequestView): Observable<Array<Categoria>> {
        return this.http.sendRequest(this.GET_CATEGORIES, request)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
}
