export class FileBase64Request {
    constructor()
    constructor(consultaId: number, contentType: string, createdDate: Date)
    constructor(consultaId?: number, contentType?: string, createdDate?: Date) {
        if (consultaId) this.consultaId = consultaId;
        if (contentType) this.contentType = contentType;
        if (createdDate) this.fechaCreacion = createdDate;
    }

    consultaId: number;
    contentType: string;
    fechaCreacion: Date = new Date();
}