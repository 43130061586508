import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestMethod, Response} from "@angular/http";
import {RequestConfig, RestResponse} from "../../shared/rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {PageImpl} from "../../common/http/PageImpl";
import {Profile} from "./profiles.classes";

@Injectable()
export class ProfilesService {
    private CREATE_CODE = new RequestConfig("PRF", "application/json", true, RequestMethod.Post, "/profiles");
    private EDIT_CODE = new RequestConfig("PRF", "application/json", true, RequestMethod.Put, "/profiles/{profileId}");
    private DELETE_CODE = new RequestConfig("PRF", "application/json", true, RequestMethod.Delete, "/profiles/{profileId}");
    public GET_CODE = new RequestConfig("PRF", "application/json", true, RequestMethod.Get, "/profiles/{profileId}");
    public GET_ALL_CODE = new RequestConfig("PRF", "application/json", true, RequestMethod.Get, "/profiles/all");
    public GET_PAGE = new RequestConfig("PRF", "application/json", true, RequestMethod.Get, "/profiles");
    private GET_TREE_CODE = new RequestConfig("PRF", "application/json", true, RequestMethod.Get, "/profiles/tree");

    constructor(private http: CustomHttp) {
    }

    /**
     * Envia una petición a WS para guardar perfil
     */
    save(profile: Profile): Observable<Response> {
        return this.http.sendRequest(this.CREATE_CODE, profile)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de un perfil
     */
    get(profileId: number): Observable<Profile> {
        return this.http.sendRequest(this.GET_CODE, {}, profileId)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de los perfiles
     */
    getAll(): Observable<Array<Profile>> {
        return this.http.sendRequest(this.GET_ALL_CODE)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener el arbol de permisos general
     */
    getTree(): Observable<Array<RestResponse>> {
        return this.http.sendRequest(this.GET_TREE_CODE)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de perfil
     */
    update(profile: Profile): Observable<Response> {
        return this.http.sendRequest(this.EDIT_CODE, profile, profile.profileId)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar el perfil
     */
    delete(profileId: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, profileId)
            .catch(error => this.http.handleError(error));
    }

    getPage(options: any): Observable<PageImpl<Profile>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
}
