import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestMethod, Response} from "@angular/http";
import {RequestConfig, RestResponse} from "../../shared/rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {PageImpl} from "../../common/http/PageImpl";
import {Module} from "./modules.classes";

@Injectable()
export class ModulesService {
    private CREATE_CODE = new RequestConfig("MOD", "application/json", true, RequestMethod.Post, "/modules");
    private EDIT_CODE = new RequestConfig("MOD", "application/json", true, RequestMethod.Put, "/modules/{moduleId}");
    private DELETE_CODE = new RequestConfig("MOD", "application/json", true, RequestMethod.Delete, "/modules/{moduleId}");
    private GET_CODE = new RequestConfig("MOD", "application/json", true, RequestMethod.Get, "/modules/{moduleId}");
    private GET_PAGE = new RequestConfig("MOD", "application/json", true, RequestMethod.Get, "/modules");

    constructor(private http: CustomHttp) {
    }

    /**
     * Envia una petición a WS para guardar modulo
     */
    save(module: Module): Observable<Response> {
        return this.http.sendRequest(this.CREATE_CODE, module)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de un modulo
     */
    get(moduleId: number): Observable<Module> {
        return this.http.sendRequest(this.GET_CODE, {}, moduleId)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de modulo
     */
    update(module: Module): Observable<Response> {
        return this.http.sendRequest(this.EDIT_CODE, module, module.moduleId)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar datos de cliente
     */
    delete(moduleId: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, moduleId)
            .catch(error => this.http.handleError(error));
    }

    getPage(options: any): Observable<PageImpl<Module>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
}
