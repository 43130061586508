import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestMethod, Response} from "@angular/http";
import {RequestConfig} from "../../shared/rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {PageImpl} from "../../common/http/PageImpl";
import {Autorizacion} from "./autorizacion.classes";

@Injectable()
export class AutorizacionService {
    //Autorizacion
    CREATE_CODE = new RequestConfig("AUT", "application/json", true, RequestMethod.Post, "/oauth/details");
    EDIT_CODE = new RequestConfig("AUT", "application/json", true, RequestMethod.Put, "/oauth/details/{authId}");
    DELETE_CODE = new RequestConfig("AUT", "application/json", true, RequestMethod.Delete, "/oauth/details/{authId}");
    GET_CODE = new RequestConfig("AUT", "application/json", true, RequestMethod.Get, "/oauth/details/{authId}");
    GET_PAGE = new RequestConfig("AUT", "application/json", true, RequestMethod.Get, "/oauth/details");

    constructor(private http: CustomHttp) {
    }

    /**
     * Envia una petición a WS para guardar autorizacion
     */
    saveAutorizacion(autorizacion: Autorizacion): Observable<Response> {
        return this.http.sendRequest(this.CREATE_CODE, autorizacion)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de un autorizacion
     */
    getAutorizacion(id: number): Observable<Autorizacion> {
        return this.http.sendRequest(this.GET_CODE, {}, id)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de autorizacion
     */
    updateAutorizacion(autorizacion: Autorizacion): Observable<Response> {
        return this.http.sendRequest(this.EDIT_CODE, autorizacion, autorizacion.id)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar datos de autorizacion
     */
    deleteAutorizacion(id: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, id)
            .catch(error => this.http.handleError(error));
    }

    getPage(options: any): Observable<PageImpl<Autorizacion>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
}
