export class Profile {
    public active: boolean;
    public profileId: number;
    public profileName: string;
    public profilePermissions: Array<ProfilePermission> = [];
    public tree: Array<Branch> = [];
}

export class ProfilePermission {
    public permissionId: number;
}

export class Branch {
    id: number;
    content: string;
    children: Array<Branch>;
    active: boolean;
    module: boolean;
    expanded: boolean;
}
