import {Injectable} from "@angular/core";
import {RequestMethod, Response} from "@angular/http";
import {Observable} from "rxjs";
import {RequestConfig, RestResponse} from "../../shared/rest/rest.request";
import {CustomHttp} from "../../common/http/custom.http";
import {PageImpl} from "../../common/http/PageImpl";
import {GroupView} from "./group.classes";


@Injectable()
export class GroupsService {
    private GET_ALL_CODE = new RequestConfig("GRU", "application/json", true, RequestMethod.Get, "/groups/all");
    private CREATE_CODE = new RequestConfig("GRU", "application/json", true, RequestMethod.Post, "/groups");
    private EDIT_CODE = new RequestConfig("GRU", "application/json", true, RequestMethod.Put, "/groups/{groupId}");
    private DELETE_CODE = new RequestConfig("GRU", "application/json", true, RequestMethod.Delete, "/groups/{groupId}");
    private GET_CODE = new RequestConfig("GRU", "application/json", true, RequestMethod.Get, "/groups/{groupId}");
    private GET_PAGE = new RequestConfig("GRU", "application/json", true, RequestMethod.Get, "/groups");

    constructor(private http: CustomHttp) {
    }

    /**
     * Envia una petición a WS para guardar grupo
     */
    saveGroups(group: GroupView): Observable<Response> {
        return this.http.sendRequest(this.CREATE_CODE, group)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de un grupo
     */
    getGroups(groupId: number): Observable<GroupView> {
        return this.http.sendRequest(this.GET_CODE, {}, groupId)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición a WS para obtener detalles de los grupos
     */
    getAll(): Observable<Array<GroupView>> {
        return this.http.sendRequest(this.GET_ALL_CODE)
            .map((res: Response) => res.json())
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envía petición a WS para editar datos de grupo
     */
    updateGroups(group: GroupView): Observable<Response> {
        return this.http.sendRequest(this.EDIT_CODE, group, group.groupId)
            .catch(error => this.http.handleError(error));
    }

    /**
     * Envia petición al servidor para eliminar datos de grupo
     */
    deleteGroups(groupId: number) {
        return this.http.sendRequest(this.DELETE_CODE, {}, groupId)
            .catch(error => this.http.handleError(error));
    }

    getPage(options: any): Observable<PageImpl<GroupView>> {
        return this.http.sendRequest(this.GET_PAGE, options)
            .map(res => res.json())
            .catch(error => this.http.handleError(error));
    }
}
