import {Component, OnChanges, ElementRef, Input, Output, EventEmitter, Renderer} from '@angular/core';
@Component({
    selector: 'sa-tree-view',
    template: '<div class="sa-tree-view tree perfiles-tree"></div>',
})
export class DrawTreeComponent implements OnChanges {
    @Input() items: any;
    @Output() change = new EventEmitter<any>();

    constructor(private el: ElementRef,
                private renderer: Renderer) {
    }

    ngOnChanges() {
        this.items && this.render();
    } 

    private render() {
        const root = this.el.nativeElement.getElementsByTagName('div')[0];
        $(root).empty();
        root.appendChild(this.createBranch(this.items, {expanded: true}));
        this.change.emit()
    }

    private createChild(item) {
        let i, branch, iconFolder = '';
        const li = document.createElement('li');
        const span = document.createElement('span');
        const text = document.createElement('div');
        const checkbox = document.createElement('input');
        const label = document.createElement('label');
        label.classList.add("checkbox-styled");

        li.appendChild(span);

        checkbox.type = 'checkbox';
        checkbox.value = item.id;
        if (item.active)
            checkbox.defaultChecked = true;
        if (item.readonly)
            checkbox.disabled = true;
        if (!item.module)
            checkbox.setAttribute('permission', '');

        if (item.children) {
            iconFolder = '';
            li.className += ' parent_li';
            item.expanded = false; //this force the permission modules hidden by default
            i = this.addToggleIcons(li, item);
            branch = this.createBranch(item.children, item);
            li.appendChild(branch)
        }
        text.innerHTML = iconFolder + item.content;
        text.style.display = 'inline';

        label.appendChild(checkbox);
        label.appendChild(document.createElement('i'));
        span.appendChild(label);
        span.appendChild(text);

        this.renderer.listen(span, 'click', (event) => {
            event.stopPropagation();

            if (item.children) {
                item.expanded = !item.expanded;
                this.toggleIcons(i, item);
                branch.className = item.expanded ? '' : 'hidden'
            }
        });
        this.renderer.listen(checkbox, 'click', (event: MouseEvent) => {
            event.stopPropagation();
            let parentElement: HTMLElement = checkbox.parentElement.parentElement;
            if (checkbox.parentElement.tagName === "LABEL") {
                parentElement = parentElement.parentElement;
            }

            if (item.children) {
                $(parentElement).find('input:checkbox')
                    .each((index, element: HTMLInputElement) => {
                        element.checked = checkbox.checked
                    });
            }

            let ulNodeOfClicked = parentElement.parentNode;
            this.toggleParentCheckbox(ulNodeOfClicked);
            this.change.emit()
        });
        if (label) {
            this.renderer.listen(label, 'click', (event: MouseEvent) => event.stopPropagation());
        }

        return li;
    }

    private createBranch(items, parent: any) {
        const ul = document.createElement('ul');
        items.forEach(item => {
            ul.appendChild(this.createChild(item))
        });

        ul.className = parent.expanded ? '' : 'hidden';

        return ul
    }

    private addToggleIcons(li, item) {
        let i = document.createElement('i');
        if (!item.children.length) {
            i.style.visibility = 'hidden';
        }

        this.toggleIcons(i, item);
        const span = li.getElementsByTagName('span')[0];

        if (span) {
            span.appendChild(i)
        } else {
            li.appendChild(i)
        }

        return i
    }

    private toggleIcons(i, item) {
        i.className = item.expanded ? 'fa fa-fwr fa-lg nio-icon-less' : 'fa fa-fwt fa-lg  nio-icon-plus'
    }

    private toggleParentCheckbox(nodeOfCheckbox) {
        if ($(nodeOfCheckbox).attr('role') !== 'tree') {
            let childrensChecked = true;
            $(nodeOfCheckbox).children().each(function () {
                if (childrensChecked)
                    childrensChecked = this.children[0].getElementsByTagName('input')[0].checked;
            });
            if (!!nodeOfCheckbox && !!nodeOfCheckbox.previousElementSibling && !!nodeOfCheckbox.previousElementSibling.getElementsByTagName('input').length)
                nodeOfCheckbox.previousElementSibling.getElementsByTagName('input')[0].checked = childrensChecked;
            this.toggleParentCheckbox(nodeOfCheckbox.parentNode.parentNode);
        }
    }
}
